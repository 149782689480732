import React from 'react';
import EventEditForm from './EventEditForm';
import EventRemove from './EventRemove';

const MaterialReleaseActionForm = ({ formType, closeModal }) => {
  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};

  switch (type) {
    case 'delete':
      return (
        <EventRemove
          matOutputID={value?.matOutputID}
          handleClose={handleClose}
        />
      );

    case 'edit':
      return <EventEditForm rowData={value} handleClose={handleClose} />;

    default:
      return null;
  }
};

export default MaterialReleaseActionForm;
