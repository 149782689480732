import React, { useState } from 'react';
import Alert from '../../Components/Alert';
import Loading from '../../Components/Loading/Loading';
import Action from '../../Components/Table/Action';
import Table from '../../Components/Table/Table';
import Tooltip from '../../Components/Tooltip/Tooltip';
import {
  checkBeforeDelete,
  dateFormat,
  formatNumberWithCommas,
  printHandler,
  tableActionTitle,
} from '../../Utilities/Utilities';
import DeleteOperations from './DeleteOperations';
import EventEditForm from './EventEditForm';
import EventAddForm from './EventAddForm';
import { useHistory } from 'react-router-dom';
import { filterRowAction } from '../../Redux/FilterRowData/useAction';
import { useDispatch } from 'react-redux';
import { DocumentN, DocumentNumber } from '../../GlobalTitle/GlobalTitle';
import useWarehouseEntry from '../../../MaterialValues/hooks/useWarehouseEntry';

const OperationsTable = ({ mainData, loading, getData, pageNumber }) => {
  const { setQuery } = useWarehouseEntry();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [copyRow, setCopyRow] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const handelChange = (value, row) => {
    switch (value) {
      case 'delete':
        return openDeleteForm(row);
      case 'edit':
        return openEditForm(row);
      case 'print':
        return handlePrint(row);
      case 'copy':
        return copyHandler(row);
      default:
        return null;
    }
  };

  const actionHandler = (row) => {
    const { docTypeID } = row || {};
    const actionList = [];
    if (docTypeID === 8) {
      actionList.push(
        {
          name: 'Խմբագրել',
          value: 'edit',
        },

        {
          name: 'Պատճենել',
          value: 'copy',
        },
        {
          name: 'Տպել',
          value: 'print',
        },
      );
    }
    if (docTypeID === 8) {
      actionList.push({
        name: 'Ջնջել',
        value: 'delete',
      });
    } else if (docTypeID === 9 || docTypeID === 7) {
      actionList.push({
        name: 'Ջնջել',
        value: 'delete',
      });
    }
    return actionList;
  };

  const goToThatPage = (row = {}) => {
    const { docTypeID, documentID, accountID, partnerID } = row || {};
    dispatch(filterRowAction({ currentId: documentID }));

    let pagePath = null;
    if (docTypeID === 1) {
      pagePath = 'CashWarrant';
    } else if (docTypeID === 2) {
      pagePath = 'CashWithdrawalOrder';
    } else if (docTypeID === 3) {
      pagePath = 'PaymentOrder';
    } else if (docTypeID === 6) {
      pagePath = 'ActOfPurchase';
    } else if (docTypeID === 7) {
      pagePath = 'AccountForSpendingMoney';
    } else if (docTypeID === 10) {
      pagePath = 'ClosingAccountBalances';
    } else if (docTypeID === 11) {
      pagePath = 'E-InvoicingOperations';
    } else if (docTypeID === 12) {
      pagePath = 'E-InvoicingOperations';
      dispatch(filterRowAction({ currentId: accountID }));
    } else if (docTypeID === 14) {
      pagePath = 'Warehouse_entry';
      dispatch(filterRowAction({ currentId: partnerID }));
    }

    return pagePath !== null && history.push(`/${pagePath}`);
  };

  const columnConfig = [
    {
      title: 'Ամսաթիվ',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Փաստաթղթի տեսակը',
      cell: (row) => row.docTypeName,
      customStyle: { maxWidth: 380 },
    },
    {
      title: <Tooltip content={DocumentNumber}>{DocumentN}</Tooltip>,
      cell: (row) => row.docNum,
      customStyle: { maxWidth: 83 },
    },
    {
      title: 'Գործընկեր',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 350, overflow: 'hidden' },
    },
    {
      title: 'Նշումներ',
      cell: (row) => row.description,
      customStyle: { maxWidth: 160, overflow: 'hidden' },
    },

    {
      title: 'Արժույթ',
      cell: (row) => row.currencyCode,
      customStyle: { maxWidth: 50 },
    },
    {
      title: <Tooltip content="Դեբետ հաշիվ">Դեբետ</Tooltip>,
      cell: (row) => row.accountDebit,
      customStyle: { maxWidth: 72 },
    },
    {
      title: 'Արժույթ',
      cell: (row) => row.credCurrCode,
      customStyle: { maxWidth: 52 },
    },
    {
      title: <Tooltip content="Կրեդիտ հաշիվ">Կրեդիտ</Tooltip>,
      cell: (row) => row.accountCredit,
      customStyle: { maxWidth: 72 },
    },

    {
      title: 'Փոխարժեք',
      cell: (row) => row.exchangeRate,
      customStyle: { maxWidth: 75 },
    },
    {
      title: 'Գումարը',
      cell: (row) => formatNumberWithCommas(row.amount),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Գումար AMD',
      cell: (row) =>
        !formatNumberWithCommas(row.amountAMD)
          ? formatNumberWithCommas(row.amount * row.exchangeRate)
          : formatNumberWithCommas(row.amountAMD),
      customStyle: { maxWidth: 130 },
    },

    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={handelChange}
          actionItem={actionHandler(row)}
          actionMess={null}
          onClick={goToThatPage}
          modalIsOpen={openDeleteModal || openEditModal || copyRow}
          mainAction={
            row?.docTypeID === 9 || row?.docTypeID === 7 ? 'delete' : 'edit'
          }
          mainActionName={
            row?.docTypeID === 9 || row?.docTypeID === 7 ? 'Ջնջել' : 'Խմբագրել'
          }
          showArrow={row?.docTypeID === 13 ? false : true}
          show={row?.docTypeID === 8 || row?.docTypeID === 9 ? false : true}
        />
      ),
      customStyle: { maxWidth: 140 },
    },
  ];

  const handlePrint = (row) => {
    const { entryID, docTypeID } = row || {};
    if (docTypeID === 8) {
      printHandler(`printOrder?entryID=${entryID}`, dispatch);
    }
  };

  const openEditForm = (row) => {
    if (row?.docTypeID === 8 || row?.docTypeID === 12) {
      setOpenEditModal(row);
    } else {
      setOpenEditModal(false);
    }
  };

  const openDeleteForm = (row) => {
    const { entryID, docTypeID } = row;
    if (
      docTypeID === 8 ||
      docTypeID === 7 ||
      docTypeID === 9 ||
      docTypeID === 10
    ) {
      checkBeforeDelete(`letDeleteEntry?EntryID=${entryID}`).then((res) => {
        if (res.errorCode === 0) {
          setOpenDeleteModal({ entryID, docTypeID });
        }
        if (res.errorCode > 0) {
          setErrorHandler(res.message);
        }
      });
    }
  };
  const copyHandler = (row) => {
    setCopyRow(row);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table
          customClass="L-operations-table"
          data={mainData}
          columnConfig={columnConfig}
        />
      ) : (
        ''
      )}

      {openEditModal && (
        <EventEditForm
          rowData={openEditModal}
          closeModal={setOpenEditModal}
          setErrorHandler={setErrorHandler}
        />
      )}

      {copyRow && (
        <EventAddForm
          rowData={copyRow}
          closeModal={setCopyRow}
          activeIsCopy={true}
          setErrorHandler={setErrorHandler}
          getData={getData}
          pageNumber={pageNumber}
        />
      )}
      {openDeleteModal && (
        <DeleteOperations
          selectRowID={openDeleteModal}
          closeModal={setOpenDeleteModal}
          setErrorHandler={setErrorHandler}
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default OperationsTable;
