import React, { useEffect, useState } from 'react';
import Modal from '../../../Components/Modal/Modal';
import Calendar from '../../../Components/Calendar/Calendar';
import {
  Commas,
  dataTimeFormat,
  dateFormat,
  initialDate,
  removeCommas,
} from '../../../Utilities/Utilities';
import SelectAccount from '../../../Components/SelectBox/SelectAccount';
import GetSentDataBtn from '../../../Components/GetSentDataBtn/GetSentDataBtn';
import { getRequest, postRequest } from '../../../Api/Api';
import NumericFormatFiled from '../../../Components/NumericFormat/NumericFormatFiled';
import Table from '../../../Components/Table/Table';
import Tooltip from '../../../Components/Tooltip/Tooltip';
import Checkbox from '../../../Components/Checkbox/Checkbox';

const GetOperationData = ({
  handleClose,
  excelInvoiceID,
  setErrorHandler,
  fetchData,
}) => {
  const [operationData, setOperationData] = useState(null);
  const fetchInvoiceEnterData = () => {
    getRequest(`getExcelInvoiceForEntry?excelInvoiceID=${excelInvoiceID}`)
      .then((res) => {
        setOperationData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      });
  };

  useEffect(() => {
    if (excelInvoiceID) {
      fetchInvoiceEnterData(excelInvoiceID);
    }
  }, [excelInvoiceID]);

  const mainAmount = operationData?.amount;
  const initRowsAmount = operationData?.rowsAmount;

  const [values, setValues] = useState({});
  const [amount, setAmount] = useState(mainAmount);
  const [rowsAmount, setRowsAmount] = useState(initRowsAmount);
  const [docDate, setDocDate] = useState(initialDate);
  const [partnerAccountVal, setPartnerAccountVal] = useState({
    label: '',
    value: '',
    id: '',
  });
  const [initInvoiceGood, setInitInvoiceGood] = useState(
    operationData?.invGoods,
  );
  const {
    accountType,
    partnerName,
    invoiceSerialNumber,
    supplierDate,
    excelInvoiceId,
    byRows,
    companyTaxationID,
  } = operationData || {};

  const formatDocDate = dataTimeFormat(docDate);

  useEffect(() => {
    setInitInvoiceGood(operationData?.invGoods);
  }, [operationData?.invGoods]);

  useEffect(() => {
    setRowsAmount(initRowsAmount);
  }, [initRowsAmount]);

  useEffect(() => {
    setAmount(mainAmount);
  }, [mainAmount]);

  const changeDebitCreditAccount = (data) => {
    const { recivedWrittenOff } = data || {};
    if (recivedWrittenOff === 1) {
      return {
        fistTitle: 'Կրեդիտ հաշիվը',
        accountValue: data?.accountCredit,
        secondTitle: 'Դեբետ հաշիվը',
      };
    }
    if (recivedWrittenOff === 2) {
      return {
        fistTitle: 'Դեբետ հաշիվը',
        accountValue: data?.accountDebit,
        secondTitle: 'Կրեդիտ հաշիվը',
      };
    }
  };
  const debitCreditValue = changeDebitCreditAccount(operationData);

  const formulationTitle = [
    'Տեսակը',
    'Գործընկեր',
    'Սերիա և համարը',
    'Մատակարարման ամսաթիվ',
    debitCreditValue?.fistTitle,
  ];

  const formulationValue = [
    accountType,
    partnerName,
    invoiceSerialNumber,
    dateFormat(supplierDate),
    debitCreditValue?.accountValue,
  ];

  const handleAccountChange = (value) => {
    if (!value) {
      value = {
        value: '',
      };
    }
    setPartnerAccountVal(value);
  };

  useEffect(() => {
    const addValueObj = {
      excelInvoiceID: excelInvoiceID,
      docDate: formatDocDate,
      accountCode: partnerAccountVal.value,
      amount: amount,
      rowsAmount: rowsAmount,
    };
    setValues({ ...values, ...addValueObj });
  }, [
    amount,
    formatDocDate,
    excelInvoiceID,
    partnerAccountVal.value,
    rowsAmount,
  ]);

  const goodsServicesList = [
    {
      title: <Tooltip content="">Հ/հ </Tooltip>,
      cell: (row) => row.goodNumber,
      customStyle: { maxWidth: 60 },
    },
    {
      title: 'Անվանում',
      cell: (row) => row.description,
      customStyle: { maxWidth: 700, overflow: 'hidden' },
    },
    {
      title: 'Չափման միավոր',
      cell: (row) => row.unit,
      customStyle: { maxWidth: 110 },
    },
    {
      title: 'Քանակը',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Միավորի գինը',
      cell: (row) => Commas(row.price),
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Զեղչի %',
      cell: (row) => Commas(row.ef),
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Ակցիզային հարկ',
      cell: (row) => Commas(row.eta),
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.totalPrice),
      customStyle: { maxWidth: 140 },
    },
    {
      title: 'ԱԱՀ',
      cell: (row) => Commas(row.vat),
      customStyle: { maxWidth: 110 },
    },
    {
      title: 'Ընդամենը',
      cell: (row) => Commas(row.total),
      customStyle: { maxWidth: 110 },
    },
    {
      title: 'Ձևակերպված',
      cell: (row) => (
        <Checkbox
          value={row.entrySign}
          checked={row.entrySign}
          onChange={() => amountFormulation(row)}
          disabled={disabledEntrySign(row)}
        />
      ),
      customStyle: { maxWidth: 90 },
    },
  ];

  const disabledEntrySign = (row) =>
    row.entryID > 0 || row.totalPrice === 0 || row.total === 0 || row.matSign;

  const amountFormulation = (row) => {
    const updatedArray = initInvoiceGood?.map((item) => {
      if (item.invGoodID === row.invGoodID) {
        return {
          ...item,
          entrySign: !item.entrySign,
        };
      } else {
        return item;
      }
    });
    row.entrySign = !row.entrySign;
    setInitInvoiceGood(updatedArray);
    const isChecked = updatedArray.filter(
      (val) => val.entrySign && !val.entryID,
    );

    const total = isChecked.reduce((prevTotalPrice, val) => {
      const checkPrices = companyTaxationID === 1 ? val.totalPrice : val.total;

      return val.entrySign
        ? prevTotalPrice + checkPrices
        : prevTotalPrice - checkPrices;
    }, 0);

    setRowsAmount(total);
  };

  const disableBtn = (val) => {
    if (removeCommas(val?.amount) > mainAmount) {
      return true;
    }
    if (val.accountCode === '') {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    postRequest('saveExcelInvoiceEntry', {
      ...values,
      amount: removeCommas(values.amount),
      rowsAmount: removeCommas(rowsAmount),
      invGoods: initInvoiceGood?.map((i) => ({
        ...i,
        excelInvoiceID: excelInvoiceId,
      })),
    })
      .then((res) => {
        const { errorCode, message, toBeContinued } = res.data;
        if (errorCode === 0 && toBeContinued === false) {
          handleClose();
        } else if (errorCode === 0) {
          setPartnerAccountVal({
            value: '',
          });
          setRowsAmount(0);

          fetchInvoiceEnterData(excelInvoiceID);
        } else if (errorCode > 0) {
          setErrorHandler(message);
        } else {
          setErrorHandler(message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <Modal
      closeHandler={handleClose}
      onDrag={true}
      customClass="L-operations-modal"
    >
      <div className="L-operations-modal-content">
        <div className="G-modal-block-title">
          <h3>Գործառնություն</h3>
        </div>
        <div className="L-operations-wrapper G-flex">
          <div className="L-operations-panel">
            {formulationTitle &&
              formulationTitle?.map((title, index) => {
                return <h3 key={index}>{title}</h3>;
              })}
          </div>
          <div className="L-operations-panel">
            {formulationValue &&
              formulationValue?.map((value, index) => {
                return <p key={index}>{value ? value : '...'}</p>;
              })}
          </div>
        </div>
        <div className="L-operations-wrapper G-flex G-border-style ">
          <div className="L-operations-panel">
            <h3>Ձևակերպման ամսաթիվ </h3>
            <h3>
              <span className="G-asterisk">*</span>
              Գումարը
            </h3>
            <h3>
              <span className="G-asterisk">*</span>
              {debitCreditValue?.secondTitle}{' '}
            </h3>
          </div>
          <div className="L-operations-panel">
            <div className="G-form-data-picker">
              <Calendar
                selected={docDate}
                changeHandler={(e) => setDocDate(e, 'docDate')}
                name="docDate"
              />
            </div>
            <div className="G-flex L-amount-flex">
              <div className="G-amount-input">
                <NumericFormatFiled
                  value={amount}
                  name="amount"
                  changeHandler={(e) => setAmount(e.target.value)}
                  disabled={byRows}
                />
              </div>
              <div className="G-amount-input">
                <NumericFormatFiled
                  value={rowsAmount}
                  name="rowsAmount"
                  changeHandler={(e) => setRowsAmount(e.target.value)}
                  disabled={!byRows}
                />
              </div>
            </div>
            <SelectAccount
              customClass="L-operations-account"
              title=""
              accountData={operationData?.partnerAccounts}
              accountVal={partnerAccountVal}
              selectChangeAccount={(values) => handleAccountChange(values)}
              requeuedAccount={false}
              disabled={!operationData?.partnerAccounts?.length}
            />
            <GetSentDataBtn
              cancelPrintClick={handleClose}
              confirmExecuteClick={handleSubmit}
              executeClass={disableBtn ? 'G-disabled-button' : ''}
              executeDisabled={disableBtn(values)}
            />
          </div>
        </div>
        <div className="L-operation-data-table">
          {initInvoiceGood !== null &&
          initInvoiceGood &&
          initInvoiceGood?.length ? (
            <Table
              customClass=""
              data={operationData?.invGoods}
              columnConfig={goodsServicesList}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </Modal>
  );
};

export default GetOperationData;
