import React, { useEffect } from 'react';
import EventEditForm from './EventEditForm';
import EventRemove from './EventRemove';
import { printHandler } from '../../../App/Utilities/Utilities';
import { useDispatch } from 'react-redux';

const Forms = ({ formType, params, closeModal }) => {
  const handleClose = () => closeModal(false);
  const { type, value } = formType || {};
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (type === 'print') {
  //     const url = `printMatInput?MatInputDocID=${value?.matInputDocID}`;
  //     printHandler(url, dispatch);
  //     closeModal(false);
  //   }
  // }, [type, value, dispatch, closeModal]);

  switch (type) {
    case 'delete':
      return (
        <EventRemove
          matOutputDocID={value?.matOutputDocID}
          handleClose={handleClose}
        />
      );
    case 'edit':
      return (
        <EventEditForm
          params={params}
          rowData={value}
          handleClose={handleClose}
        />
      );

    // case 'operations':
    //   return (
    //     <EntryOrderOperations
    //       matInputDocID={value?.matInputDocID}
    //       handleClose={handleClose}
    //       params={params}
    //     />
    //   );

    // case 'operationExecution':
    //   return (
    //     <EntryOrderOperationExecution
    //       matInputDocID={value?.matInputDocID}
    //       handleClose={handleClose}
    //       params={params}
    //     />
    //   );

    // case 'print':
    //   return null;

    default:
      return null;
  }
};

export default Forms;
