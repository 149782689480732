import React, { useEffect, useState } from 'react';
import Table from '../../../../App/Components/Table/Table';
import Checkbox from '../../../../App/Components/Checkbox/Checkbox';
import { Commas, dateFormat } from '../../../../App/Utilities/Utilities';

const MaterialStockBalanceTable = ({ matBalance, setUpdateBalance }) => {
  const [checkSign, setCheckSign] = useState(false);
  useEffect(() => {
    const checkEveryItem = matBalance?.every((item) => item.sign);
    setCheckSign(checkEveryItem);
  }, [matBalance]);

  const handleChangeQuantity = (e, targetRow) => {
    let { value } = e.target;

    value = value.replace(',', '.');

    if (!/^\d*\.?\d{0,4}$/.test(value)) return;

    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) return;

    if (numericValue <= targetRow.balAmount) {
      const updatedRows = matBalance?.map((row) =>
        row.rowID === targetRow.rowID
          ? {
              ...row,
              amount: value,
              sign: numericValue !== 0,
              totalPrice: numericValue * targetRow.price,
            }
          : row,
      );
      setUpdateBalance(updatedRows);
    }
  };

  const updateCheckboxes = (e, row = null) => {
    const { checked } = e.target;

    const updatedList = matBalance?.map((item) =>
      row
        ? item.rowID === row.rowID
          ? {
              ...item,
              sign: checked,
              amount: checked ? row.balAmount : 0,
            }
          : item
        : {
            ...item,
            sign: checked,
            amount: checked ? row.balAmount : 0,
          },
    );

    setUpdateBalance(updatedList);
  };

  const columnConfig = [
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.inputDate),
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գինը',
      cell: (row) => Commas(row.price),
      customStyle: { maxWidth: 180 },
    },
    {
      title: 'Առկա է',
      cell: (row) => row.balAmount,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.balTotalPrice),
      customStyle: { maxWidth: 180 },
    },
    {
      title: 'Քանակը',
      cell: (row) => (
        <input
          type="text"
          value={row.amount}
          onChange={(e) => handleChangeQuantity(e, row)}
          onFocus={(e) => {
            if (e.target.value == '0') {
              e.target.value = '';
            }
          }}
        />
      ),
      isEdit: true,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.totalPrice),
      customStyle: { maxWidth: 160 },
    },
    {
      title: (
        <span className="G-flex">
          <Checkbox onChange={(e) => updateCheckboxes(e)} checked={checkSign} />
        </span>
      ),
      cell: (row) => (
        <Checkbox
          checked={row.sign}
          value={row.sign}
          onChange={(e) => updateCheckboxes(e, row)}
        />
      ),
      customStyle: { maxWidth: 80 },
    },
  ];

  return (
    <>
      {matBalance !== null && matBalance?.length ? (
        <Table
          customClass="L-mat-stock-balance-table G-table-has-scroll"
          data={matBalance}
          columnConfig={columnConfig}
        />
      ) : null}
    </>
  );
};

export default MaterialStockBalanceTable;
