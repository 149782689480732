import * as Yup from 'yup';
import { SelectFieldMessage } from './ErrorMessage';
const requiredMessage = (field) => `${field} չի կարող լինել դատարկ`;

export const materialReleaseValid = () =>
  Yup.object().shape({
    amount: Yup.number()
      .required(requiredMessage('Քանակը'))
      .nullable()
      .min(0, 'Քանակը չի կարող լինել բացասական')
      .test(
        'decimal-places',
        'Քանակը կարող է ունենալ առավելագույնը 4 տասնորդական նշան',
        (value) => {
          if (value === undefined || value === null) return true;
          return /^\d+(\.\d{1,4})?$/.test(value.toString());
        },
      ),

    matValueName: Yup.string()
      .required(requiredMessage('Նյութի անունը'))
      .nullable(),
  });

export const warehouseReleaseFormValid = () =>
  Yup.object().shape({
    storeName: Yup.string().required(SelectFieldMessage),
    partnerAccCode: Yup.string().required(SelectFieldMessage),
    partnerName: Yup.string().when('ident', {
      is: (ident) => ident === true,
      then: Yup.string().required(SelectFieldMessage),
      otherwise: Yup.string().notRequired(),
    }),
  });
