import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataTimeFormat, simplifyObject } from '../../App/Utilities/Utilities';
import {
  addWarehouseReleaseEntry,
  fetchBeforeRemoveReleaseEntry,
  fetchMaterialStockBalance,
  fetchProductMaterialValue,
  fetchReleaseOrderDocNum,
  fetchReleaseOrderExcel,
  fetchWarehouseReleaseEntryForm,
  fetchWarehouseReleaseOrder,
  fetchWarehouseReleaseTimePeriod,
  removeWarehouseReleaseEntry,
} from '../services/fetchWarehouseReleaseOrder';
import {
  deleteReleaseEntryOrderAction,
  getReleaseEntryOrderAction,
  getWarehouseReleaseOrderAction,
  productMaterialValueAction,
  tableIsUpdateAction,
} from '../../App/Redux/materialValuesStor/warehouseReleaseOrderSlices/useAction';

const useWarehouseReleaseOrder = () => {
  const { currentCompanyID, userID } = useSelector((state) => state.auth);
  const {
    warehouseReleaseOrderData,
    warehouseReleaseEntryOrder,
    tableIsUpdate,
  } = useSelector((state) => state.warehouseReleaseOrderData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const [query, setQuery] = useState({ pageNumber: 0 });
  const [matBalance, setMatStockBalance] = useState([]);
  const dispatch = useDispatch();

  const [updateMatBalance, setUpdateMatBalance] = useState(matBalance);

  useEffect(() => {
    setUpdateMatBalance(matBalance);
  }, [matBalance]);

  const modifyQuery = (query) => {
    return simplifyObject({
      ...query,
      companyID: currentCompanyID,
    });
  };

  const getWarehouseReleaseTimePeriod = async () => {
    try {
      setIsLoading(true);
      const response = await fetchWarehouseReleaseTimePeriod(userID);
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouseReleaseOrder = async (query = '') => {
    try {
      setIsLoading(true);
      const modifiedQuery = modifyQuery(query);
      const response = await fetchWarehouseReleaseOrder(modifiedQuery);

      dispatch(getWarehouseReleaseOrderAction(response));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const initializeData = async () => {
    try {
      const { dateStart, dateEnd } = await getWarehouseReleaseTimePeriod();
      const updatedQuery = modifyQuery({
        ...query,
        startDate: dataTimeFormat(dateStart),
        endDate: dataTimeFormat(dateEnd),
      });
      setQuery(updatedQuery);
      await getWarehouseReleaseOrder(updatedQuery);
    } catch (error) {
      console.error('Error initializing data:', error);
    }
  };

  const getWarehouseReleaseEntryForm = async (matOutputDocID) => {
    try {
      setIsLoading(true);
      const response = await fetchWarehouseReleaseEntryForm(
        currentCompanyID,
        matOutputDocID,
      );
      dispatch(getReleaseEntryOrderAction(response));
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };
  const getReleaseOrderExcel = async (param) => {
    setIsLoading(true);
    try {
      const data = await fetchReleaseOrderExcel(currentCompanyID, param);
      return data;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getReleaseOrderDocNum = async (values) => {
    const { storeNo, year } = values || {};

    try {
      const response = await fetchReleaseOrderDocNum(
        currentCompanyID,
        storeNo,
        year,
      );
      return response;
    } catch (error) {
      setError(error);
    }
  };

  const addReleaseEntry = async (newReleaseEntry, query, closeFormCallback) => {
    try {
      const { errorCode, message } = await addWarehouseReleaseEntry(
        newReleaseEntry,
      );

      if (errorCode === 0) {
        if (closeFormCallback) closeFormCallback();
        dispatch(tableIsUpdateAction({ tableIsUpdate: false }));
        await getWarehouseReleaseOrder(query);
      } else {
        setErrorMess(
          message || 'Ինչ-որ բան սխալ է գնացել, խնդրում ենք կրկին փորձել։',
        );
      }
    } catch (error) {
      setErrorMess(error.message || 'Սխալ: Խնդրում ենք կրկին փորձել։');
    }
  };

  const getBeforeRemoveReleaseEntry = async (matOutputDocID) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await fetchBeforeRemoveReleaseEntry(
        matOutputDocID,
      );
      if (errorCode !== 0) {
        return setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveReleaseEntry = async (
    matOutputDocID,
    closeFormCallback,
  ) => {
    try {
      const { errorCode, message } = await removeWarehouseReleaseEntry(
        matOutputDocID,
      );
      if (errorCode === 0) {
        dispatch(deleteReleaseEntryOrderAction(matOutputDocID));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const getProductMaterialValue = async (storeNo) => {
    try {
      const data = await fetchProductMaterialValue(currentCompanyID, storeNo);
      dispatch(productMaterialValueAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMaterialStockBalance = async (storeNo, matValueNo, docNum = 0) => {
    try {
      const data = await fetchMaterialStockBalance(
        currentCompanyID,
        storeNo,
        matValueNo,
        docNum,
      );

      setMatStockBalance(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError(null);
      setErrorMess(null);
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    error,
    errorMess,
    setErrorMess,
    query,
    setQuery,
    getWarehouseReleaseOrder,
    warehouseReleaseOrderData,
    initializeData,
    getWarehouseReleaseEntryForm,
    warehouseReleaseEntryOrder,
    getReleaseOrderExcel,
    getReleaseOrderDocNum,
    addReleaseEntry,
    handleRemoveReleaseEntry,
    getBeforeRemoveReleaseEntry,
    getProductMaterialValue,
    getMaterialStockBalance,
    updateMatBalance,
    setUpdateMatBalance,
    tableIsUpdate,
  };
};

export default useWarehouseReleaseOrder;
