export const GeneralVacationFields = [
  {
    fieldType: 'input',
    type: 'text',
    name: 'orderNum',
    label: 'Հրամանը',
    value: '',
    required: false,
    maxLength: 15,
  },
  {
    fieldType: 'vacationDate',
    dateTitle: 'Արձակուրդի տրման ժամանակահատված',
    required: true,

    dateFields: [
      {
        dateName: 'periodStart',
        required: false,
        minDate: (hireDate) => hireDate.getFullYear() + 1,
        maxDate: (hireDate) => new Date(hireDate.getFullYear() + 3, 11, 31),
      },
      {
        dateName: 'periodEnd',
        required: false,
        disabled: true,
        minDate: (hireDate) => hireDate.getFullYear() + 1,
        maxDate: (hireDate) => new Date(hireDate.getFullYear() + 3, 11, 31),
      },
    ],
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Հավելումի տեսակը',
    required: true,
    fieldName: 'stName',
    dropdownId: 'stNo',
    optionName: 'salaryTypes',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.stName,
        id: i.stNo,
        value: i.stName,
      }));
    },
  },

  {
    fieldType: 'date',
    dateName: 'vacStartDate',
    dateTitle: 'Արձակուրդի սկիզբը',
    required: true,
  },
  {
    fieldType: 'input',
    type: 'number',
    name: 'vacDays',
    label: 'Արձակուրդի օրերը',
    value: '',
    required: false,
    maxLength: 2,
  },
  {
    fieldType: 'date',
    dateName: 'vacEndDate',
    dateTitle: 'Արձակուրդի վերջը',
    required: false,
    disabled: true,
  },

  {
    fieldType: 'input',
    type: 'number',
    name: 'avgDays',
    label: 'Միջին օրերի թիվը',
    value: '',
    required: false,
    maxLength: 2,
    disabled: true,
  },
  {
    fieldType: 'amount',
    name: 'monthlyAvg',
    label: 'Ամսական միջին աշխատավարձը',
    value: '',
    required: false,
    disabled: true,
  },
  {
    fieldType: 'amount',
    name: 'dailyAvg',
    label: 'Օրական միջին աշխատավարձը',
    value: '',
    required: false,
    disabled: true,
  },
  {
    fieldType: 'amount',
    name: 'amount',
    label: 'Հաշվարկվել է',
    value: '',
    required: false,
    disabled: true,
  },
];
