import React, { useState } from 'react';
import { warehouseReleaseFormFields } from './FieldsConfig';
import Input from '../../components/Input/Input';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import ErrorFiled from '../../../App/Components/ErrorFiled/ErrorFiled';
import DateComponent from '../../components/dateComponent/DateComponent';
import { dataTimeFormat, dateFormat } from '../../../App/Utilities/Utilities';
import AddNew from '../../../App/Elements/Add/AddNew';
import { useDispatch } from 'react-redux';
import { isUpdateStoreNum } from '../../../App/Redux/materialValuesStor/warehouseReleaseOrderSlices/useAction';

const WarehouseReleaseEntryForm = ({
  handleChange,
  touched,
  setFieldValue,
  values,
  handleBlur,
  errors,
  warehouseReleaseEntryOrder,
  getReleaseOrderDocNum,
  getProductMaterialValue,
}) => {
  const [openNewItemForm, setOpenNewItemForm] = useState(false);

  let dropDownOptions = [];
  const dispatch = useDispatch();
  const updateDocNum = async (storeNo, date) => {
    const year = new Date(date).getFullYear();
    const docNum = await getReleaseOrderDocNum({ storeNo, year });
    setFieldValue('docNum', docNum);
  };

  const onDropdownChange = async (selectedOption, dropdownId, fieldName) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: '',
      };
    }
    const fieldValue = selectedOption.value;
    const dropdownItemId = selectedOption.id;
    const ident = selectedOption?.ident;

    setFieldValue(fieldName, fieldValue);
    setFieldValue(dropdownId, dropdownItemId);
    if (fieldName === 'partnerAccCode') {
      setFieldValue('ident', ident);
      if (!ident) {
        setFieldValue('partnerName', '');
        setFieldValue('partnerID', 0);
      }
    }
    if (fieldName === 'storeName') {
      dispatch(
        isUpdateStoreNum({
          storeNo: dropdownItemId,
          storeName: fieldValue,
        }),
      );
      await updateDocNum(dropdownItemId, values.docDate);

      await getProductMaterialValue(dropdownItemId);
    }
  };

  const onChangeDate = async (date, name) => {
    const formattedDate = dataTimeFormat(date);
    setFieldValue(name, formattedDate);
    await updateDocNum(values.storeNo, date);
  };

  return (
    <div className="L-warehouse-release-entry-form">
      {warehouseReleaseFormFields?.map((field, index) => {
        const {
          fieldType,
          fieldName,
          label,
          name,
          required,
          Children,
          optionName,
          dropdownTitle,
          type,
          dropdownId,
          disabled,
          maxLength,
          addNewItem,
        } = field;
        const uniqueKey = fieldName || name || `field-${index}`;
        const fieldError = touched[name] && errors[name];
        const mainClassName = 'G-warehouse-release-entry-filed';
        const isRequired =
          typeof required === 'function' ? required(values) : required;

        const isDisabled =
          typeof disabled === 'function' ? disabled(values) : disabled;

        if (typeof Children === 'function') {
          if (warehouseReleaseEntryOrder) {
            dropDownOptions = Children(warehouseReleaseEntryOrder[optionName]);
          }
        }

        switch (fieldType) {
          case 'input':
            return (
              <div key={uniqueKey} className={mainClassName}>
                <Input
                  type={type}
                  inputTitle={label}
                  name={name}
                  requiredField={required}
                  onChange={handleChange}
                  value={values[name]}
                  onBlur={handleBlur}
                  disabled={isDisabled}
                  maxLength={maxLength}
                />
              </div>
            );

          case 'date':
            return (
              <div key={uniqueKey} className={mainClassName}>
                <DateComponent
                  value={dateFormat(values[field.dateName] || '')}
                  onChange={(date) => onChangeDate(date, field.dateName)}
                  name={field.dateName}
                  title={field.dateTitle}
                  requiredFiled={field.required}
                  openToDate={new Date(values[field.dateName] || new Date())}
                />
              </div>
            );

          case 'dropdown':
            return (
              <div
                key={uniqueKey}
                className={`${mainClassName}  G-dropdown-flex-block`}
              >
                <SelectControl
                  selectBoxTitle={dropdownTitle}
                  requiredFiled={isRequired}
                  value={{
                    label: values[fieldName],
                    value: values[fieldName],
                  }}
                  onBlur={handleBlur}
                  options={dropDownOptions || []}
                  onChange={(selectedOption) =>
                    onDropdownChange(selectedOption, dropdownId, fieldName)
                  }
                  disabled={isDisabled}
                />
                {fieldError && <ErrorFiled error={fieldError} />}
                {addNewItem && (
                  <AddNew
                    onClick={() => setOpenNewItemForm(fieldName)}
                    content={dropdownTitle}
                  />
                )}
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default WarehouseReleaseEntryForm;
