export const fieldConfigs = (method) => {
  const filedList = [
    {
      fieldType: 'dropdown',
      dropdownTitle: 'ՆԱ անվանումը',
      required: true,
      fieldName: 'matValueName',
      dropdownId: 'matValueNo',
      optionName: 'matValues',
      Children: (props) => {
        return props?.map((i) => ({
          label: i.matValueName,
          id: i.matValueNo,
          value: i.matValueName,
          accCode: i.accCode,
          accName: i.accName,
          unit: i.unit,
          price: i.price,
          balAmount: i.balAmount,
          totalPrice: i.totalPrice,
        }));
      },
    },
  ];

  if (method) {
    filedList.push(
      {
        fieldType: 'input',
        title: 'Առկա քանակը',
        name: 'balAmount',
        value: '',
        disabled: true,
      },

      {
        fieldType: 'amount',
        title: 'Միջին գինը',
        name: 'price',
        value: '',
        disabled: true,
      },
      {
        fieldType: 'input',
        title: 'Քանակը',
        name: 'amount',
        maxLength: 6,
        required: true,
      },
      {
        fieldType: 'amount',
        title: 'Գումարը',
        value: '',
        name: 'totalPrice',
        disabled: true,
      },
    );
  }
  return filedList;
};
