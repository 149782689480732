import { createAction } from '../../actionCreators';
import {
  FETCH_WARE_HOUSE_RELEASE_ORDER,
  FETCH_RELEASE_ENTRY_ORDER,
  DELETE_WARE_HOUSE_RELEASE_ORDER,
  ADD_NEW_MATERIAL_RELEASE,
  UPDATE_MATERIAL_RELEASE,
  DELETE_MATERIAL_RELEASE,
  FETCH_PRODUCT_MATERIAL_VALUE,
  IS_UPDATE_STORE_NUM,
  PRODUCT_TABLE_IS_UPDATE,
} from './constants';

export const getWarehouseReleaseOrderAction = createAction(
  FETCH_WARE_HOUSE_RELEASE_ORDER,
);

export const productMaterialValueAction = createAction(
  FETCH_PRODUCT_MATERIAL_VALUE,
);

export const getReleaseEntryOrderAction = createAction(
  FETCH_RELEASE_ENTRY_ORDER,
);

export const deleteReleaseEntryOrderAction = createAction(
  DELETE_WARE_HOUSE_RELEASE_ORDER,
);

export const addMaterialRelease = createAction(ADD_NEW_MATERIAL_RELEASE);
export const updateMaterialRelease = createAction(UPDATE_MATERIAL_RELEASE);
export const deleteMaterialRelease = createAction(DELETE_MATERIAL_RELEASE);
export const isUpdateStoreNum = createAction(IS_UPDATE_STORE_NUM);
export const tableIsUpdateAction = createAction(PRODUCT_TABLE_IS_UPDATE);
