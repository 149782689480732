import {
  DELETE_MATERIAL_VAL_E_INVOICING,
  FETCH_MATERIAL_VAL_E_INVOICING,
  UPDATE_MATERIAL_VAL_CODE,
} from './constants';

const initialState = {
  materialValuesEInvoicing: {},
};

export const materialValuesEInvoicingReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_MATERIAL_VAL_E_INVOICING:
      return {
        ...state,
        materialValuesEInvoicing: payload,
      };

    case UPDATE_MATERIAL_VAL_CODE:
      return {
        ...state,
        materialValuesEInvoicing: {
          ...state.materialValuesEInvoicing,
          tableData: state.materialValuesEInvoicing?.tableData?.map((item) =>
            item.invGoodID === payload.invGoodID
              ? { ...item, matValueNo: payload?.matValueNo }
              : item,
          ),
        },
      };

    case DELETE_MATERIAL_VAL_E_INVOICING:
      return {
        ...state,
        materialValuesEInvoicing: {
          ...state.materialValuesEInvoicing,
          tableData: state.materialValuesEInvoicing?.tableData.filter(
            (item) => item.invGoodID !== payload,
          ),
        },
      };

    default:
      return { ...state };
  }
};
