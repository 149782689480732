import React, { useEffect, useState } from 'react';
import Action from '../../../App/Components/Table/Action';
import Table from '../../../App/Components/Table/Table';
import ProductTotal from '../warehouseEntry/ProductTotal';
import MaterialReleaseActionForm from './materialReleaseForm/MaterialReleaseActionForm';
import useWarehouseReleaseOrder from '../../hooks/useWarehouseReleaseOrder';
import { Commas, tableActionTitle } from '../../../App/Utilities/Utilities';
import { warehouseReleaseFormTableItems } from '../../Config/tableConfig';
import checkIcon from '../../assets/icons/check.svg';
import { tableIsUpdateAction } from '../../../App/Redux/materialValuesStor/warehouseReleaseOrderSlices/useAction';
import { useDispatch } from 'react-redux';

const WarehouseReleaseFormTable = ({
  updateProductTable,
  setUpdateProductTable,
}) => {
  const { warehouseReleaseEntryOrder } = useWarehouseReleaseOrder();
  const [totalPrice, setTotalPrice] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setTotalPrice(
      updateProductTable?.reduce(
        (sum, item) => sum + (item.totalPrice || 0),
        0,
      ) || 0,
    );
  }, [updateProductTable]);

  const handleAction = (type, row) => {
    setShowForm({ type, value: row });
  };

  const handleChangeQuantity = (e, targetRow) => {
    let { value } = e.target;

    dispatch(tableIsUpdateAction({ tableIsUpdate: true }));
    value = value.replace(',', '.');

    if (!/^\d*\.?\d{0,4}$/.test(value)) return;

    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) return;

    if (numericValue <= targetRow.balAmount) {
      const updatedRows = updateProductTable?.map((row) =>
        row.rowID === targetRow.rowID
          ? {
              ...row,
              amount: value,
              sign: false,
              totalPrice: numericValue * targetRow.price,
            }
          : row,
      );
      setUpdateProductTable(updatedRows);
    }
  };
  const columnConfig = [
    {
      title: 'ՆԱ Կոդը',
      cell: (row) => row.matValueNo,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'ՆԱ անվանումը',
      cell: (row) => row.matValueName,
      customStyle: { maxWidth: 280, overflow: 'hidden' },
    },
    {
      title: 'Չ/Մ',
      cell: (row) => row.unit,
      customStyle: { maxWidth: 100, overflow: 'hidden' },
    },
    {
      title: 'Քանակը',
      cell: (row) => (
        <input
          type="text"
          value={row.amount}
          onChange={(e) => handleChangeQuantity(e, row)}
          onFocus={(e) => {
            if (e.target.value == '0') {
              e.target.value = '';
            }
          }}
        />
      ),
      isEdit: true,
      customStyle: { maxWidth: 120 },
    },
    {
      title: 'Գինը',
      cell: (row) => Commas(row.price),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.totalPrice),
      customStyle: { maxWidth: 150 },
    },
    {
      title: 'Հաշիվ',
      cell: (row) => row.accCode,
      customStyle: { maxWidth: 100, overflow: 'hidden' },
    },
    {
      title: 'Հաստատված',
      cell: (row) =>
        row.sign && (
          <img className="G-confirm-icon" src={checkIcon} alt="checkIcon" />
        ),
      customStyle: { maxWidth: 90 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={handleAction}
          actionItem={warehouseReleaseFormTableItems(
            warehouseReleaseEntryOrder?.method,
          )}
          mainAction={
            warehouseReleaseEntryOrder?.method === 1 ? 'delete' : 'edit'
          }
          mainActionName={
            warehouseReleaseEntryOrder?.method === 1 ? 'Ջնջել' : 'Խմբագրել'
          }
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 140 },
    },
  ];

  return (
    <>
      {updateProductTable?.length > 0 && (
        <Table
          data={updateProductTable}
          columnConfig={columnConfig}
          customClass="L-warehouse-release-prod-table"
        />
      )}
      <ProductTotal total={totalPrice} />
      <MaterialReleaseActionForm formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default WarehouseReleaseFormTable;
