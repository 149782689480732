import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Modal from '../../../App/Components/Modal/Modal';
import Button from '../../../App/Components/Button/Button';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import WarehouseReleaseEntryForm from './WarehouseReleaseEntryForm';
import useWarehouseReleaseOrder from '../../hooks/useWarehouseReleaseOrder';
import WarehouseReleaseFormTable from './WarehouseReleaseFormTable';
import { warehouseReleaseFormValid } from '../../../App/Validation/WarehouseReleaseFormValid';
import Loading from '../../../App/Components/Loading/Loading';
import EventAddForm from './materialReleaseForm/EventAddForm';
import useIsMounted from '../../hooks/useIsMounted';

const WarehouseReleaseForm = ({
  onSubmit,
  handleClose,
  matOutputDocID = 0,
}) => {
  const {
    getWarehouseReleaseEntryForm,
    warehouseReleaseEntryOrder,
    getReleaseOrderDocNum,
    errorMess,
    setErrorMess,
    isLoading,
    getProductMaterialValue,
    tableIsUpdate,
  } = useWarehouseReleaseOrder();
  const [showProductForm, setShowProductForm] = useState(false);
  const [updateProductTable, setUpdateProductTable] = useState(
    warehouseReleaseEntryOrder?.outputs,
  );
  useEffect(() => {
    setUpdateProductTable(warehouseReleaseEntryOrder?.outputs);
  }, [warehouseReleaseEntryOrder?.outputs]);
  const isMounted = useIsMounted();

  useEffect(() => {
    const fetchWarehouseReleaseData = async () => {
      if (isMounted.current) {
        await getWarehouseReleaseEntryForm(matOutputDocID);
      }
    };
    fetchWarehouseReleaseData();

    return () => {};
  }, [matOutputDocID, isMounted]);

  const {
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    isValid,
    dirty,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    mode: 'onBlur',
    enableReinitialize: true,
    initialValues: { ...warehouseReleaseEntryOrder?.matOutput },
    validationSchema: warehouseReleaseFormValid,
    onSubmit: async (values, { resetForm }) => {
      if (isMounted.current) {
        await onSubmit(values, resetForm);
      }
    },
  });

  const handleProductFormToggle = () => {
    setShowProductForm((prev) => !prev);
  };

  const commonProps = {
    warehouseReleaseEntryOrder,
    getReleaseOrderDocNum,
    getProductMaterialValue,
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    isValid,
    dirty,
    handleSubmit,
    handleClose,
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Modal
      onDrag={true}
      closeHandler={handleClose}
      customClass="L-warehouse-entry-form"
    >
      <WarehouseReleaseEntryForm {...commonProps} />

      <div className="L-product-add-btn">
        <Button
          disabled={!values?.storeNo}
          customClass={!values?.storeNo ? 'G-disabled-button' : ''}
          onClick={handleProductFormToggle}
        />
      </div>

      <WarehouseReleaseFormTable
        updateProductTable={updateProductTable}
        setUpdateProductTable={setUpdateProductTable}
        errorMess={errorMess}
        setErrorMess={setErrorMess}
      />

      <GetSentDataBtn
        cancelPrintClick={handleClose}
        confirmExecuteClick={handleSubmit}
        executeClass={
          (!dirty || !isValid) && !tableIsUpdate ? 'G-disabled-button' : ''
        }
        executeDisabled={(!dirty || !isValid) && !tableIsUpdate}
      />

      {showProductForm && (
        <EventAddForm handleClose={handleProductFormToggle} />
      )}
    </Modal>
  );
};

export default WarehouseReleaseForm;
