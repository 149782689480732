import React from 'react';
import Alert from '../../../App/Components/Alert';
import useWarehouseReleaseOrder from '../../hooks/useWarehouseReleaseOrder';
import WarehouseReleaseForm from './WarehouseReleaseForm';
import useIsMounted from '../../hooks/useIsMounted';

const EventAddForm = ({ handleClose, params }) => {
  const {
    errorMess,
    setErrorMess,
    warehouseReleaseEntryOrder,
    addReleaseEntry,
  } = useWarehouseReleaseOrder();

  const isMounted = useIsMounted();

  const onAddHandler = async (values, resetForm) => {
    try {
      await addReleaseEntry(
        {
          matOutput: { ...values, docNum: Number(values.docNum) },
          outputs: warehouseReleaseEntryOrder?.outputs,
        },
        params,
        handleClose,
      );
      if (isMounted) {
        resetForm();
      }
    } catch (error) {
      if (isMounted) {
        setErrorMess(error.message);
      }
    }
  };

  return (
    <>
      <WarehouseReleaseForm onSubmit={onAddHandler} handleClose={handleClose} />
      {errorMess && (
        <Alert description={errorMess} onClose={() => setErrorMess(null)} />
      )}
    </>
  );
};

export default EventAddForm;
