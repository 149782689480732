import React, { useEffect, useState } from 'react';
import useWarehouseEntry from '../../hooks/useWarehouseEntry';
import Header from '../../../App/Components/Header/Header';
import EventAddForm from './EventAddForm';
import WarehouseEntryFilter from './WarehouseEntryFilter';
import WarehouseEntryTable from './WarehouseEntryTable';
import Pagination from '../../../App/Components/Pagination/Pagination';
import Button from '../../../App/Components/Button/Button';
import EInvoiceEntry from '../eInvoiceEntry/EInvoiceEntry';
import { simplifyObject } from '../../../App/Utilities/Utilities';
import './style.scss';

const WarehouseEntry = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEInvoiceEntry, setShowEInvoiceEntry] = useState(false);

  const {
    initializeData,
    query,
    setQuery,
    wareHouseEntryData,
    isLoading,
    getWarehouseEntry,
    getWarehouseEntryExcel,
  } = useWarehouseEntry();

  const modifyQuery = (query) => {
    return simplifyObject({
      ...query,
    });
  };

  const fetchData = async (query) => {
    try {
      await getWarehouseEntry(query);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    fetchData(query);
  }, [query.pageNumber]);

  const handleSearch = () => {
    fetchData(query);
  };

  const handlePageChange = (pageNumber) => {
    setQuery((prev) => ({
      ...prev,
      pageNumber: pageNumber,
    }));
  };

  const toggleVisibility = (setter) => setter((prev) => !prev);

  return (
    <>
      <Header
        pageTitle="Պահեստի մուտքի օրդեր"
        handelClick={() => toggleVisibility(setShowAddForm)}
      />
      <div className="L-E-Invoicing-btn">
        <Button
          text="Մուտք E - invoicing -ից"
          onClick={() => toggleVisibility(setShowEInvoiceEntry)}
        />
      </div>
      <WarehouseEntryFilter
        query={query}
        setQuery={setQuery}
        wareHouseEntryData={wareHouseEntryData}
        getExcelData={() => getWarehouseEntryExcel(modifyQuery(query))}
        onSearch={handleSearch}
      />
      <WarehouseEntryTable
        warehouseEntry={wareHouseEntryData?.matInputDocs}
        isLoading={isLoading}
      />
      <Pagination
        pageCount={wareHouseEntryData?.pageCount?.pageCount || 0}
        setPageNumber={handlePageChange}
        currentPage={query.pageNumber}
      />
      {showAddForm && !isLoading && (
        <EventAddForm
          handleClose={() => toggleVisibility(setShowAddForm)}
          params={query}
        />
      )}
      {showEInvoiceEntry && (
        <EInvoiceEntry
          handleClose={() => toggleVisibility(setShowEInvoiceEntry)}
          partnerID={query?.partnerID?.id || 0}
        />
      )}
    </>
  );
};

export default WarehouseEntry;
