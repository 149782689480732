export const warehouseReleaseFormFields = [
  {
    fieldType: 'input',
    type: 'text',
    name: 'docNum',
    label: 'Փաստաթուղթ N',
    value: '',
    required: true,
    maxLength: 8,
    disabled: (v) => {
      return !v.storeName;
    },
  },
  {
    fieldType: 'date',
    dateName: 'docDate',
    dateTitle: 'Կազմման ամսաթիվ',
    required: true,
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'Պահեստ',
    required: true,
    fieldName: 'storeName',
    dropdownId: 'storeNo',
    optionName: 'stores',

    Children: (props) => {
      return props?.map((i) => ({
        label: i.storeName,
        id: i.storeNo,
        value: i.storeName,
      }));
    },
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'Դեբետ հաշիվ',
    fieldName: 'partnerAccCode',
    dropdownId: 'partnerAccCode',
    optionName: 'partnerAccounts',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.partnerAccName,
        id: i.partnerAccCode,
        value: i.partnerAccName,
        ident: i.ident,
      }));
    },
    required: true,
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Գործընկեր',
    fieldName: 'partnerName',
    dropdownId: 'partnerID',
    optionName: 'partners',
    addNewItem: true,
    Children: (props) => {
      return props?.map((i) => ({
        label: i.partnerName,
        id: i.partnerID,
        value: i.partnerName,
      }));
    },
    required: (i) => {
      return i.ident;
    },
    disabled: (i) => {
      return !i.ident;
    },
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'note',
    label: 'Նշումներ',
    value: '',
    required: false,
    maxLength: 100,
  },
];

export const warehouseReleaseFilterFields = [
  {
    calendarTitle: 'Ժամանակահատվածը',
    fieldType: 'calendar',
    calendars: [
      {
        name: 'startDate',
      },
      {
        name: 'endDate',
      },
    ],
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Պահեստ',
    required: false,
    fieldName: 'storeName',
    dropdownId: 'storeNo',
    optionName: 'docStores',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.storeName,
        id: i.storeNo,
        value: i.storeName,
      }));
    },
  },
  {
    fieldType: 'dropdown',
    dropdownTitle: 'Գործընկեր',
    required: false,
    fieldName: 'partnerName',
    dropdownId: 'partnerID',
    optionName: 'docPartners',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.partnerName,
        id: i.partnerID,
        value: i.partnerName,
      }));
    },
  },

  {
    fieldType: 'dropdown',
    dropdownTitle: 'ՀՀ տարածք/Ներմուծում',
    required: false,
    fieldName: 'supplyName',
    dropdownId: 'supplyID',
    optionName: 'docSupplies',
    Children: (props) => {
      return props?.map((i) => ({
        label: i.supplyName,
        id: i.supplyID,
        value: i.supplyName,
      }));
    },
  },

  {
    fieldType: 'amount',
    type: 'text',
    name: 'fromMoney',
    inputTitle: 'Մին. գումարը',
    value: 0,
    required: false,
    maxLength: 15,
  },
  {
    fieldType: 'amount',
    type: 'text',
    name: 'toMoney',
    inputTitle: 'Մաքս. գումարը',
    value: 0,
    required: false,
    maxLength: 15,
  },
  {
    fieldType: 'input',
    type: 'number',
    name: 'docNum',
    inputTitle: 'Փաստաթուղթ N',
    value: '',
    required: false,
    maxLength: 8,
  },
];
