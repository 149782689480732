import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Loading from '../../../App/Components/Loading/Loading';
import checkIcon from '../../assets/icons/check.svg';
import {
  Commas,
  dateFormat,
  tableActionTitle,
} from '../../../App/Utilities/Utilities';
import Action from '../../../App/Components/Table/Action';
import { warehouseEntryActionItems } from '../../Config/tableConfig';

import Tooltip from '../../../App/Components/Tooltip/Tooltip';
import Forms from './Forms';

const WarehouseReleaseTable = ({ matOutputDocs, isLoading, params }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Ամսաթիվը',
      cell: (row) => dateFormat(row.docDate),
      customStyle: { maxWidth: 90 },
    },
    {
      title: 'Պահեստը',
      cell: (row) => row.storeName,
      customStyle: { maxWidth: 550, overflow: 'hidden' },
    },
    {
      title: 'Գործընկերը',
      cell: (row) => row.partnerName,
      customStyle: { maxWidth: 450, overflow: 'hidden' },
    },
    {
      title: 'Փաստ. N',
      cell: (row) => row.docNum,
      customStyle: { maxWidth: 100 },
    },

    {
      title: 'ՀՀ տարածք/Ներմուծում',
      cell: (row) => row.supplyName,
      customStyle: { maxWidth: 180, overflow: 'hidden' },
    },

    {
      title: 'Գումարը',
      cell: (row) => Commas(row.amount),
      customStyle: { maxWidth: 160 },
    },

    {
      title: <Tooltip content="Հաշվապահական թղթակցություն">Հաշվ./թղթ.</Tooltip>,

      cell: (row) =>
        row.sign ? (
          <img className="G-confirm-icon" src={checkIcon} alt="checkIcon" />
        ) : null,
      customStyle: { maxWidth: 90 },
    },
    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(type, row) =>
            setShowForm({
              type: type,
              value: row,
            })
          }
          actionItem={warehouseEntryActionItems}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 150 },
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {matOutputDocs !== null && matOutputDocs && matOutputDocs?.length ? (
        <Table
          customClass=""
          data={matOutputDocs}
          columnConfig={columnConfig}
        />
      ) : null}

      <Forms formType={showForm} params={params} closeModal={setShowForm} />
    </>
  );
};

export default WarehouseReleaseTable;
