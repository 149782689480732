import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';

export const fetchWarehouseReleaseOrder = async (params) => {
  const queryString = urlEncodeQueryParams(params);
  const endpoint = `getMatOutputs?${queryString}`;

  try {
    const response = await getRequest(endpoint);
    if (!response || !response.data) {
      throw new Error('Invalid response from the server');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const fetchWarehouseReleaseTimePeriod = async (userID) => {
  try {
    const response = await getRequest(
      `getMatOutputSearchDates?userID=${userID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchWarehouseReleaseEntryForm = async (
  currentCompanyID,
  matOutputDocID,
) => {
  try {
    const response = await getRequest(
      `getMatOutput?companyID=${currentCompanyID}&matOutputDocID=${matOutputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchReleaseOrderExcel = async (currentCompanyID, query) => {
  const queryRoot = urlEncodeQueryParams(query);

  try {
    const response = await getRequest(
      `getMatOutputsForExcel?companyID=${currentCompanyID}&${queryRoot}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
export const fetchReleaseOrderDocNum = async (
  currentCompanyID,
  storeNo,
  year,
) => {
  if (!storeNo) return;
  try {
    const response = await getRequest(
      `getMatOutputNo?companyID=${currentCompanyID}&storeNo=${storeNo}&year=${year}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const addWarehouseReleaseEntry = async (newReleaseEntry) => {
  try {
    const response = await postRequest('saveMatOutput', newReleaseEntry);
    return response.data;
  } catch (error) {
    console.error('Error adding warehouse Release Entry:', error);
    throw error;
  }
};

export const fetchBeforeRemoveReleaseEntry = async (matOutputDocID) => {
  try {
    const response = await getRequest(
      `preDeleteEditMatOutput?matOutputDocID=${matOutputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting Warehouse Release Entry:', error);
    throw error;
  }
};

export const removeWarehouseReleaseEntry = async (matOutputDocID) => {
  try {
    const response = await deleteRequest(
      `deleteMatOutput?matOutputDocID=${matOutputDocID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting Warehouse Release Entry:', error);
    throw error;
  }
};

export const fetchProductMaterialValue = async (companyID, storeNo) => {
  try {
    const response = await getRequest(
      `getMatValuesBalance?companyID=${companyID}&storeNo=${storeNo}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting Warehouse Release Entry:', error);
    throw error;
  }
};

export const fetchMaterialStockBalance = async (
  currentCompanyID,
  storeNo,
  matValueNo,
  docNum = 0,
) => {
  if (!currentCompanyID) return;
  try {
    const response = await getRequest(
      `getMatValuesFifoBalance?companyID=${currentCompanyID}&storeNo=${storeNo}&matValueNo=${matValueNo}&docNum=${docNum}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};
