import { useEffect, useState } from 'react';
import {
  addEntryOpExecution,
  fetchEntryOperationExecution,
  fetchEntryOrderOperations,
  removeEntryOrderOperation,
} from '../services/fetchWarehouseEntryOperations';
import { useSelector } from 'react-redux';
import useWarehouseEntry from './useWarehouseEntry';

const useWarehouseEntryOperations = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [entryOperationData, setEntryOperationData] = useState([]);
  const [entryOperationExecution, setEntryOperationExecution] = useState([]);
  const { getWarehouseEntry } = useWarehouseEntry();
  const getEntryOrderOperations = async (matInputDocID) => {
    setIsLoading(true);
    try {
      const response = await fetchEntryOrderOperations(
        currentCompanyID,
        matInputDocID,
      );

      setEntryOperationData(response);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const removeEntryOperation = async (
    matInputDocID,
    params,
    closeFormCallback,
  ) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await removeEntryOrderOperation(
        matInputDocID,
      );

      if (errorCode === 0) {
        getWarehouseEntry(params);
        closeFormCallback();
      }

      if (message) {
        setErrorMessage(message);
      }
    } catch (err) {
      console.error('Error removing operation:', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };
  // create hook for entryOrderOperationExecution

  const getEntryOperationExecution = async (matInputDocID) => {
    setIsLoading(true);
    try {
      const response = await fetchEntryOperationExecution(
        currentCompanyID,
        matInputDocID,
      );
      if (response.message) {
        setErrorMessage(response.message);
      } else {
        setEntryOperationExecution(response);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };
  const saveEntryOpExecution = async (newEnter, params, closeFormCallback) => {
    try {
      const { errorCode, message } = await addEntryOpExecution(newEnter);

      if (errorCode === 0) {
        getWarehouseEntry(params);
        closeFormCallback();
      } else {
        setErrorMessage(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    return () => {
      setError(null);
      setErrorMessage(null);
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    error,
    errorMessage,
    setErrorMessage,
    getEntryOrderOperations,

    removeEntryOperation,
    entryOperationData,
    setEntryOperationData,
    getEntryOperationExecution,
    entryOperationExecution,
    saveEntryOpExecution,
  };
};

export default useWarehouseEntryOperations;
