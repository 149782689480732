import React from 'react';
import Input from '../../../components/Input/Input';
import NumericFormatFiled from '../../../../App/Components/NumericFormat/NumericFormatFiled';
import { removeCommas } from '../../../../App/Utilities/Utilities';
import useWarehouseReleaseOrder from '../../../hooks/useWarehouseReleaseOrder';
import { fieldConfigs } from './fieldConfigs';
import { isMethod } from '../utils';

const MaterialStockBalanceFiled = ({
  values,
  setFieldValue,
  handleChange,
  onBlur,
  handleSubmit,
}) => {
  const { warehouseReleaseEntryOrder } = useWarehouseReleaseOrder();

  const onFieldChange = (e) => {
    const { name, value } = e.target;

    const numericValue = parseFloat(value);

    if (name === 'amount') {
      setFieldValue(name, value);
      setFieldValue('totalPrice', numericValue * removeCommas(values.price));
      return;
    }

    handleChange(e);
  };

  return (
    <form onSubmit={handleSubmit}>
      {fieldConfigs(isMethod(warehouseReleaseEntryOrder)).map(
        (field, index) => {
          const {
            fieldType,
            title,
            name,
            required,
            maxLength,
            addNewItem,
            disabled,
          } = field;
          const isRequired =
            typeof required === 'function' ? required(values) : required;
          const fieldMaxLength =
            typeof maxLength === 'function' ? maxLength(values) : maxLength;

          const fieldClass = `L-material-release-form-filed ${
            addNewItem ? 'G-dropdown-flex-block' : ''
          }`;

          if (fieldType === 'input') {
            return (
              <div key={index} className={fieldClass}>
                <Input
                  type="text"
                  inputTitle={title}
                  name={name}
                  requiredField={isRequired}
                  onChange={onFieldChange}
                  value={values[name]}
                  maxLength={fieldMaxLength}
                  onBlur={onBlur}
                  disabled={disabled}
                />
              </div>
            );
          }

          if (fieldType === 'amount') {
            return (
              <div key={name} className={fieldClass}>
                <NumericFormatFiled
                  inputTitle={title}
                  name={name}
                  requiredFiled={isRequired}
                  changeHandler={handleChange}
                  value={values[name]}
                  onBlur={onBlur}
                  disabled={disabled}
                />
              </div>
            );
          }

          return null;
        },
      )}
    </form>
  );
};

export default MaterialStockBalanceFiled;
