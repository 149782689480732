import React from 'react';
import AcceptOrCancelModal from '../../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { useDispatch } from 'react-redux';
import { deleteMaterialRelease } from '../../../../App/Redux/materialValuesStor/warehouseReleaseOrderSlices/useAction';

const EventRemove = ({ matOutputID, handleClose }) => {
  const dispatch = useDispatch();
  const onRemoveHandler = async () => {
    dispatch(
      deleteMaterialRelease({ matOutputID: matOutputID, tableIsUpdate: true }),
    );
    handleClose();
  };

  return (
    <>
      <AcceptOrCancelModal
        closeModal={handleClose}
        confirmClick={onRemoveHandler}
      />
    </>
  );
};

export default EventRemove;
