import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import Modal from '../../../../App/Components/Modal/Modal';
import { SelectControl } from '../../../../App/Components/SelectControl/SelectControl';
import GetSentDataBtn from '../../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import useWarehouseReleaseOrder from '../../../hooks/useWarehouseReleaseOrder';
import { fieldConfigs } from './fieldConfigs';
import { materialReleaseValid } from '../../../../App/Validation/WarehouseReleaseFormValid';
import MaterialStockBalanceTable from './MaterialStockBalanceTable';
import MaterialStockBalanceFiled from './MaterialStockBalanceFiled';
import { isMethod } from '../utils';
import { randomID, removeCommas } from '../../../../App/Utilities/Utilities';

const MaterialReleaseForm = ({ initValues = {}, onSubmit, handleClose }) => {
  const {
    warehouseReleaseEntryOrder,
    getMaterialStockBalance,
    updateMatBalance,
    setUpdateMatBalance,
  } = useWarehouseReleaseOrder();

  const initalStoreNo = warehouseReleaseEntryOrder?.matOutput.storeNo;
  const method = warehouseReleaseEntryOrder?.method;
  const isMethodOne = method === 1;
  const isMethodTwo = method === 2;

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: useMemo(
      () => (isMethodTwo ? materialReleaseValid : null),
      [isMethodTwo],
    ),

    onSubmit: (values, { resetForm }) => {
      const finalValues = isMethod(warehouseReleaseEntryOrder)
        ? [
            {
              ...values,
              price: removeCommas(values.price),
              amount: parseFloat(values.amount),
              purPrice: removeCommas(values.purPrice),
              matOutputID: values.matOutputID || randomID(),
            },
          ]
        : updateMatBalance.map((item) => ({
            ...item,
            amount: parseFloat(item.amount),
          }));
      onSubmit(finalValues, resetForm);
    },
    enableReinitialize: true,
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    isValid,
    dirty,
    errors,
    handleBlur,
  } = formik;

  const handleSelectChange = async (
    fieldName,
    dropdownId,
    selectedOption = {},
  ) => {
    const defaultValues = {
      value: '',
      id: '',
      accCode: '',
      accName: '',
      price: '',
      balAmount: '',
      totalPrice: '',
    };

    const { value, id, unit, accCode, price, balAmount, totalPrice } = {
      ...defaultValues,
      ...selectedOption,
    };

    setFieldValue(fieldName, value);
    setFieldValue(dropdownId, id);

    if (fieldName === 'matValueName') {
      setFieldValue('matValueNo', id);
      setFieldValue('unit', unit);
      setFieldValue('accCode', accCode);
      setFieldValue('accName', accCode);
      setFieldValue('price', price);
      setFieldValue('balAmount', balAmount);
      setFieldValue('totalPrice', totalPrice);

      if (isMethodOne) await getMaterialStockBalance(initalStoreNo, id);
    }

    if (!selectedOption) setUpdateMatBalance([]);
  };

  const checkedAllSign =
    Array.isArray(updateMatBalance) &&
    updateMatBalance.some((i) => i.amount > 0);
  const isButtonDisabled =
    (isMethodOne && !checkedAllSign) || (isMethodTwo && (!dirty || !isValid));

  return (
    <Modal
      closeHandler={handleClose}
      customClass={
        isMethod(warehouseReleaseEntryOrder)
          ? 'L-material-release-form'
          : 'L-material-release-form-table'
      }
    >
      <form>
        {fieldConfigs(isMethod(warehouseReleaseEntryOrder)).map(
          (
            {
              fieldType,
              fieldName,
              required,
              Children,
              optionName,
              dropdownTitle,
              dropdownId,
            },
            index,
          ) =>
            fieldType === 'dropdown' && (
              <div key={index} className="L-material-release-form-filed">
                <SelectControl
                  selectBoxTitle={dropdownTitle}
                  requiredFiled={
                    typeof required === 'function' ? required(values) : required
                  }
                  value={{
                    label: values[fieldName] || '',
                    value: values[fieldName] || '',
                  }}
                  onChange={(selectedOption) =>
                    handleSelectChange(fieldName, dropdownId, selectedOption)
                  }
                  onBlur={handleBlur}
                  options={
                    Children
                      ? Children(warehouseReleaseEntryOrder[optionName])
                      : []
                  }
                />
              </div>
            ),
        )}
        {values.accCode && (
          <div className="L-material-release-form-filed ">
            <p className="G-title-p">Հաշիվը</p>
            <div className="L-account-box">
              <strong>{values.accCode}</strong>
            </div>
          </div>
        )}
      </form>
      {isMethod(warehouseReleaseEntryOrder) ? (
        <MaterialStockBalanceFiled
          values={values}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          onBlur={handleBlur}
        />
      ) : (
        <MaterialStockBalanceTable
          matBalance={updateMatBalance}
          setUpdateBalance={setUpdateMatBalance}
        />
      )}
      <GetSentDataBtn
        cancelPrintClick={handleClose}
        confirmExecuteClick={handleSubmit}
        executeClass={isButtonDisabled ? 'G-disabled-button' : ''}
        executeDisabled={isButtonDisabled}
      />
    </Modal>
  );
};

export default MaterialReleaseForm;
