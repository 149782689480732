import {
  FETCH_WARE_HOUSE_RELEASE_ORDER,
  FETCH_RELEASE_ENTRY_ORDER,
  DELETE_WARE_HOUSE_RELEASE_ORDER,
  ADD_NEW_MATERIAL_RELEASE,
  UPDATE_MATERIAL_RELEASE,
  DELETE_MATERIAL_RELEASE,
  FETCH_PRODUCT_MATERIAL_VALUE,
  IS_UPDATE_STORE_NUM,
  PRODUCT_TABLE_IS_UPDATE,
} from './constants';

const initialState = {
  warehouseReleaseOrderData: {},
  warehouseReleaseEntryOrder: {},
  tableIsUpdate: false,
};

export const warehouseReleaseOrderReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_WARE_HOUSE_RELEASE_ORDER:
      return {
        ...state,
        warehouseReleaseOrderData: payload,
      };

    case DELETE_WARE_HOUSE_RELEASE_ORDER:
      return {
        ...state,
        warehouseReleaseOrderData: {
          ...state.warehouseReleaseOrderData,
          matOutputDocs: state.warehouseReleaseOrderData.matOutputDocs.filter(
            (item) => item.matOutputDocID !== payload,
          ),
        },
      };

    case PRODUCT_TABLE_IS_UPDATE:
      return {
        ...state,
        tableIsUpdate: payload.tableIsUpdate,
      };

    case FETCH_RELEASE_ENTRY_ORDER:
      return {
        ...state,
        warehouseReleaseEntryOrder: payload,
      };

    case IS_UPDATE_STORE_NUM:
      return {
        ...state,
        warehouseReleaseEntryOrder: {
          ...state.warehouseReleaseEntryOrder,
          matOutput: {
            ...state.warehouseReleaseEntryOrder.matOutput,
            storeNo: payload.storeNo,
            storeName: payload.storeName,
          },
        },
      };

    case FETCH_PRODUCT_MATERIAL_VALUE:
      return {
        ...state,
        warehouseReleaseEntryOrder: {
          ...state.warehouseReleaseEntryOrder,
          matValues: [
            ...(state.warehouseReleaseEntryOrder.matValues || []),
            ...payload,
          ],
        },
      };

    case UPDATE_MATERIAL_RELEASE:
      return {
        ...state,
        tableIsUpdate: payload.tableIsUpdate,
        warehouseReleaseEntryOrder: {
          ...state.warehouseReleaseEntryOrder,
          outputs: state.warehouseReleaseEntryOrder.outputs.map((item) => {
            const updatedItem = payload.outputs.find(
              (output) => output.matOutputID === item.matOutputID,
            );
            return updatedItem ? { ...item, ...updatedItem } : item;
          }),
        },
      };

    case ADD_NEW_MATERIAL_RELEASE:
      return {
        ...state,
        tableIsUpdate: payload.tableIsUpdate,
        warehouseReleaseEntryOrder: {
          ...state.warehouseReleaseEntryOrder,
          outputs: Array.isArray(state.warehouseReleaseEntryOrder?.outputs)
            ? [
                ...state.warehouseReleaseEntryOrder?.outputs.filter(
                  (item) =>
                    !payload.outputs.some(
                      (p) => p.matValueNo === item.matValueNo,
                    ),
                ),
                ...payload.outputs,
              ]
            : state.warehouseReleaseEntryOrder.outputs,
        },
      };

    case DELETE_MATERIAL_RELEASE:
      return {
        ...state,
        tableIsUpdate: payload.tableIsUpdate,
        warehouseReleaseEntryOrder: {
          ...state.warehouseReleaseEntryOrder,
          outputs: state.warehouseReleaseEntryOrder.outputs.filter(
            (item) => item.matOutputID !== payload.matOutputID,
          ),
        },
      };

    default:
      return state;
  }
};
