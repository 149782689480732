import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewWarehouseEntry,
  fetchBeforeRemoveWarehouseEntry,
  fetchInvoiceEntryForm,
  fetchWarehouseEntry,
  fetchWarehouseEntryDocNum,
  fetchWarehouseEntryExcel,
  fetchWarehouseEntryForm,
  fetchWarehouseTimePeriod,
  removeWarehouseEntry,
} from '../services/fetchWarehouseEntry';
import {
  getWareHouseEntryAction,
  getWareHouseEntryFormAction,
  isTableUpdateAction,
  removeWareHouseEntryAction,
} from '../../App/Redux/materialValuesStor/warehouseEntrySlices/useAction';
import { simplifyObject } from '../../App/Utilities/Utilities';

const useWarehouseEntry = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);

  const [query, setQuery] = useState({ pageNumber: 0 });
  const { currentId } = useSelector((state) => state.filteredId);
  const { currentCompanyID, userID } = useSelector((state) => state.auth);
  const {
    wareHouseEntryData,
    warehouseEntryForm,
    warehouseEntranceID,
    isTableUpdate,
  } = useSelector((state) => state.wareHouseEntry);

  const dispatch = useDispatch();

  const modifyQuery = (query) => {
    return simplifyObject({
      ...query,
      partnerID: currentId ? currentId : query.partnerID,
    });
  };

  const getWarehouseTimePeriod = async () => {
    try {
      setIsLoading(true);
      const response = await fetchWarehouseTimePeriod(userID);
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouseEntry = async (query = '') => {
    try {
      setIsLoading(true);
      const modifiedQuery = modifyQuery(query);
      const response = await fetchWarehouseEntry(
        currentCompanyID,
        modifiedQuery,
      );
      dispatch(getWareHouseEntryAction(response));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const initializeData = async () => {
    try {
      const { dateStart, dateEnd } = await getWarehouseTimePeriod();
      const updatedQuery = modifyQuery({
        ...query,
        startDate: dateStart,
        endDate: dateEnd,
      });
      setQuery(updatedQuery);
      await getWarehouseEntry(updatedQuery);
    } catch (error) {
      console.error('Error initializing data:', error);
    }
  };

  const getWarehouseEntryForm = async (matInputDocID) => {
    setIsLoading(true);
    try {
      const data = await fetchWarehouseEntryForm(
        currentCompanyID,
        matInputDocID,
      );
      dispatch(getWareHouseEntryFormAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getInvoiceEntryForm = async (partnerID, matInputDocID) => {
    setIsLoading(true);
    try {
      const data = await fetchInvoiceEntryForm(
        currentCompanyID,
        partnerID,
        matInputDocID,
      );
      dispatch(getWareHouseEntryFormAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addWarehouseEntry = async (
    newWarehouseEntry,
    param,
    closeFormCallback,
  ) => {
    try {
      const { errorCode, message } = await addNewWarehouseEntry(
        newWarehouseEntry,
      );
      if (errorCode === 0) {
        dispatch(isTableUpdateAction({ isTableUpdate: false }));
        await getWarehouseEntry(param);
        closeFormCallback();
      } else {
        setErrorMess(message || 'ինչ-որ բան սխալ է եղել');
      }
    } catch (error) {
      setError(error);
    }
  };

  const getBeforeRemoveWarehouseEntry = async (matInputDocID) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await fetchBeforeRemoveWarehouseEntry(
        matInputDocID,
      );
      if (errorCode !== 0) {
        return setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveWarehouseEntry = async (
    matInputDocID,
    closeFormCallback,
  ) => {
    setIsLoading(true);

    try {
      const { errorCode, message } = await removeWarehouseEntry(matInputDocID);

      if (errorCode === 0) {
        dispatch(removeWareHouseEntryAction(matInputDocID));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouseEntryExcel = async (param) => {
    setIsLoading(true);
    try {
      const data = await fetchWarehouseEntryExcel(currentCompanyID, param);
      return data;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouseEntryDocNum = async (values) => {
    const { storeNo, year } = values || {};
    setIsLoading(true);
    try {
      const response = await fetchWarehouseEntryDocNum(
        currentCompanyID,
        storeNo,
        year,
      );
      return response;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError(null);
      setErrorMess(null);
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    error,
    errorMess,
    setErrorMess,
    query,
    setQuery,
    modifyQuery,
    initializeData,
    getWarehouseEntry,
    getWarehouseEntryForm,
    warehouseEntryForm,
    getInvoiceEntryForm,
    wareHouseEntryData,
    addWarehouseEntry,
    getBeforeRemoveWarehouseEntry,

    handleRemoveWarehouseEntry,
    getWarehouseEntryExcel,
    getWarehouseEntryDocNum,
    warehouseEntranceID,
    getWarehouseTimePeriod,
    isTableUpdate,
  };
};

export default useWarehouseEntry;
