import React from 'react';
import { useDispatch } from 'react-redux';
import MaterialReleaseForm from './MaterialReleaseForm';
import { updateMaterialRelease } from '../../../../App/Redux/materialValuesStor/warehouseReleaseOrderSlices/useAction';

const EventEditForm = ({ handleClose, rowData }) => {
  const dispatch = useDispatch();

  const onUpdateHandler = (values) => {
    dispatch(
      updateMaterialRelease({
        outputs: [...values],
        tableIsUpdate: true,
      }),
    );

    handleClose();
  };

  return (
    <>
      <MaterialReleaseForm
        initValues={rowData}
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
      />
    </>
  );
};

export default EventEditForm;
