import React, { useEffect } from 'react';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../../App/Components/Alert';

import useWarehouseReleaseOrder from '../../hooks/useWarehouseReleaseOrder';

const EventRemove = ({ matOutputDocID, handleClose }) => {
  const {
    errorMess,
    setErrorMess,
    getBeforeRemoveReleaseEntry,
    handleRemoveReleaseEntry,
  } = useWarehouseReleaseOrder();

  useEffect(() => {
    if (matOutputDocID) {
      getBeforeRemoveReleaseEntry(matOutputDocID);
    }
  }, [matOutputDocID]);

  const handleAlertClose = () => {
    setErrorMess('');
    handleClose();
  };
  const onRemoveHandler = async () => {
    try {
      await handleRemoveReleaseEntry(matOutputDocID, handleClose);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  return (
    <>
      {errorMess ? (
        <Alert description={errorMess} onClose={handleAlertClose} />
      ) : (
        <AcceptOrCancelModal
          closeModal={handleAlertClose}
          confirmClick={onRemoveHandler}
        />
      )}
    </>
  );
};

export default EventRemove;
