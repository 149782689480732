import { getRequest, postRequest, putRequest } from '../../App/Api/Api';
import { urlEncodeQueryParams } from '../../App/Helper/urlHelper';

export const fetchEInvoiceEntry = async (currentCompanyID, partnerID) => {
  try {
    const response = await getRequest(
      `getMatInputForInvGoods?companyID=${currentCompanyID}&partnerID=${partnerID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching:', error);
    throw error;
  }
};

export const fetchAuthenticated = async (params) => {
  const queryString = urlEncodeQueryParams(params);
  const endpoint = `getMatValuesForInput?${queryString}`;

  try {
    const response = await getRequest(endpoint);
    if (!response || !response.data) {
      throw new Error('Invalid response from the server');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const identifyProduct = async (newMatValueNo) => {
  try {
    const response = await putRequest('saveMatValueNoToInvGood', newMatValueNo);
    return response.data;
  } catch (error) {
    console.error('Error adding newMatValueNo:', error);
    throw error;
  }
};
export const deleteEntryOrderForm = async (invGoodID) => {
  try {
    const response = await putRequest(
      `DeleteMatInputInvGood?invGoodID=${invGoodID}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error remove entry order:', error);
    throw error;
  }
};
export const fetchEntryOrderFormation = async (params) => {
  const queryString = urlEncodeQueryParams(params);
  const endpoint = `getInvMatInput?${queryString}`;

  try {
    const response = await getRequest(endpoint);
    if (!response || !response.data) {
      throw new Error('Invalid response from the server');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const saveEntryOrderFormation = async (entryOrderFormation) => {
  try {
    const response = await postRequest('saveMatInput', entryOrderFormation);
    return response.data;
  } catch (error) {
    console.error('Error adding entryOrderFormation:', error);
    throw error;
  }
};
