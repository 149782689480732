export const FETCH_WARE_HOUSE_ENTRY = 'FETCH_WARE_HOUSE_ENTRY';
export const FETCH_WARE_HOUSE_FORM_ENTRY = 'FETCH_WARE_HOUSE_FORM_ENTRY';
export const UPDATE_WARE_HOUSE_ENTRY = 'UPDATE_WARE_HOUSE_ENTRY';
export const DELETE_WARE_HOUSE_ENTRY = 'DELETE_WARE_HOUSE_ENTRY';
export const ADD_WARE_HOUSE_PRODUCT = 'ADD_WARE_HOUSE_PRODUCT';
export const UPDATE_WARE_HOUSE_PRODUCT = 'UPDATE_WARE_HOUSE_PRODUCT';
export const DELETE_WARE_HOUSE_PRODUCT = 'DELETE_WARE_HOUSE_PRODUCT';
export const GET_WARE_HOUSE_ENTRANCE_ID = 'GET_WARE_HOUSE_ENTRANCE_ID';
export const UPDATE_WARE_HOUSE_DROP_DATA = 'UPDATE_WARE_HOUSE_DROP_DATA';
export const PROD_TABLE_IS_UPDATE = 'PROD_TABLE_IS_UPDATE';
