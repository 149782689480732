import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteEntryOrderForm,
  fetchAuthenticated,
  fetchEInvoiceEntry,
  fetchEntryOrderFormation,
  identifyProduct,
  saveEntryOrderFormation,
} from '../services/fetchEInvoiceEntry';
import {
  deleteMaterialEInvoicingAction,
  fetchMaterialEInvoicingAction,
  updateMaterialValCodeAction,
} from '../../App/Redux/materialValuesStor/matValuesReceivedEInvoicing/useAction';
import useWarehouseEntry from './useWarehouseEntry';

const useEInvoiceEntry = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const { materialValuesEInvoicing } = useSelector(
    (state) => state.materialValEInvoicing,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState('');
  const [authenticatedData, setAuthenticatedData] = useState(null);
  const [selectMatValueNo, setSelectMatValueNo] = useState({});

  const [entryOrderFormation, setEntryOrderFormation] = useState({});
  const dispatch = useDispatch();
  const { getWarehouseEntry } = useWarehouseEntry();

  const handleError = useCallback((error, customMessage) => {
    console.error(customMessage, error);
    setError(error);
    setErrorMess(customMessage || error?.message || 'An error occurred.');
  }, []);

  const getEInvoiceEntry = async (partnerID) => {
    setIsLoading(true);
    try {
      const data = await fetchEInvoiceEntry(currentCompanyID, partnerID);
      dispatch(fetchMaterialEInvoicingAction(data));
    } catch (err) {
      handleError(err, 'Failed to fetch E-Invoice entry.');
    } finally {
      setIsLoading(false);
    }
  };

  const getAuthenticated = async (params, description, invGoodID) => {
    setIsLoading(true);
    const updatedParams = {
      companyID: currentCompanyID,
      matValueName: description,
      invGoodID: invGoodID,
      ...params,
    };
    try {
      const data = await fetchAuthenticated(updatedParams);
      setAuthenticatedData(data);
    } catch (err) {
      handleError(err, 'Failed to fetch authenticated data.');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmIdentifyProduct = async (newMatValueNo, closeFormCallback) => {
    try {
      const { errorCode, message, id } = await identifyProduct(newMatValueNo);

      if (errorCode === 0) {
        dispatch(
          updateMaterialValCodeAction({
            invGoodID: id,
            matValueNo: selectMatValueNo?.matValueNo,
          }),
        );
        closeFormCallback?.();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (err) {
      handleError(err, 'Failed to identify product.');
    }
  };
  //fetch EntryOrder Formation

  const getEntryOrderFormation = async (partnerID) => {
    setIsLoading(true);
    const updatedParams = {
      companyID: currentCompanyID,
      partnerID: partnerID,
      matInputDocID: 0,
    };
    try {
      const data = await fetchEntryOrderFormation(updatedParams);
      setEntryOrderFormation(data);
    } catch (err) {
      handleError(err, 'Failed to fetch authenticated data.');
    } finally {
      setIsLoading(false);
    }
  };
  const saveInvoiceEntryOrder = async (newMatValueNo, closeFormCallback) => {
    try {
      const { errorCode, message, id } = await saveEntryOrderFormation(
        newMatValueNo,
      );

      if (errorCode === 0) {
        getEInvoiceEntry(0);
        dispatch(
          updateMaterialValCodeAction({
            invGoodID: id,
            matValueNo: selectMatValueNo?.matValueNo,
          }),
        );
        getWarehouseEntry();
        closeFormCallback?.();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (err) {
      handleError(err, 'Failed to identify product.');
    }
  };
  const deleteEntryOrder = async (invGoodID, closeFormCallback) => {
    setIsLoading(true);

    try {
      const { errorCode, message } = await deleteEntryOrderForm(invGoodID);

      if (errorCode === 0) {
        dispatch(deleteMaterialEInvoicingAction(invGoodID));
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError(null);
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    error,
    errorMess,
    materialValuesEInvoicing,
    authenticatedData,
    setAuthenticatedData,
    selectMatValueNo,
    setSelectMatValueNo,
    setErrorMess,
    getEInvoiceEntry,
    getAuthenticated,
    confirmIdentifyProduct,
    getEntryOrderFormation,
    entryOrderFormation,
    saveInvoiceEntryOrder,
    deleteEntryOrder,
  };
};

export default useEInvoiceEntry;
