import React, { useEffect } from 'react';
import AcceptOrCancelModal from '../../../App/Components/AcceptOrCancelModal/AcceptOrCancelModal';
import Alert from '../../../App/Components/Alert';
import useWarehouseEntry from '../../hooks/useWarehouseEntry';

const EventRemove = ({ matInputDocID, handleClose }) => {
  const {
    errorMess,
    setErrorMess,
    getBeforeRemoveWarehouseEntry,
    handleRemoveWarehouseEntry,
  } = useWarehouseEntry();

  useEffect(() => {
    if (matInputDocID) {
      getBeforeRemoveWarehouseEntry(matInputDocID);
    }
  }, [matInputDocID]);

  const onRemoveHandler = async () => {
    try {
      await handleRemoveWarehouseEntry(matInputDocID, handleAlertClose);
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };

  const handleAlertClose = () => {
    setErrorMess('');
    handleClose();
  };

  return (
    <>
      {errorMess ? (
        <Alert description={errorMess} onClose={handleAlertClose} />
      ) : (
        <AcceptOrCancelModal
          closeModal={handleAlertClose}
          confirmClick={onRemoveHandler}
        />
      )}
    </>
  );
};

export default EventRemove;
