import React, { useEffect, useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import Pagination from '../../../App/Components/Pagination/Pagination';
import WarehouseReleaseFilter from './WarehouseReleaseFilter';
import WarehouseReleaseTable from './WarehouseReleaseTable';
import useWarehouseReleaseOrder from '../../hooks/useWarehouseReleaseOrder';
import './style.scss';
import { simplifyObject } from '../../../App/Utilities/Utilities';
import EventAddForm from './EventAddForm';
const WarehouseReleaseOrder = () => {
  const {
    isLoading,
    getWarehouseReleaseOrder,
    warehouseReleaseOrderData,
    initializeData,
    query,
    setQuery,
    getReleaseOrderExcel,
  } = useWarehouseReleaseOrder();
  const { matOutputDocs } = warehouseReleaseOrderData || {};
  const [showAddForm, setShowAddForm] = useState(false);

  const modifyQuery = (query) => {
    return simplifyObject({
      ...query,
    });
  };

  const fetchData = async (query) => {
    try {
      await getWarehouseReleaseOrder(query);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    fetchData(query);
  }, [query.pageNumber]);

  const handleSearch = () => {
    fetchData(query);
  };

  const handlePageChange = (pageNumber) => {
    setQuery((prev) => ({
      ...prev,
      pageNumber: pageNumber,
    }));
  };

  const toggleVisibility = () => {
    setShowAddForm((prevState) => !prevState);
  };

  return (
    <>
      <Header pageTitle="Պահեստի ելքի օրդեր" handelClick={toggleVisibility} />
      <WarehouseReleaseFilter
        warehouseReleaseOrderData={warehouseReleaseOrderData}
        query={query}
        setQuery={setQuery}
        getExcelData={() => getReleaseOrderExcel(modifyQuery(query))}
        onSearch={handleSearch}
      />
      <WarehouseReleaseTable
        matOutputDocs={matOutputDocs}
        isLoading={isLoading}
        params={query}
      />
      <Pagination
        pageCount={warehouseReleaseOrderData?.pageCount?.pageCount || 0}
        setPageNumber={handlePageChange}
        currentPage={query.pageNumber}
      />

      {showAddForm && !isLoading && (
        <EventAddForm params={query} handleClose={toggleVisibility} />
      )}
    </>
  );
};

export default WarehouseReleaseOrder;
