export const warehouseField = [
  {
    fieldType: 'input',
    type: 'number',
    name: 'storeNo',
    label: 'Կոդը',
    value: '',
    required: true,
    maxLength: 3,
  },

  {
    fieldType: 'input',
    type: 'text',
    name: 'storeName',
    label: 'Անվանումը',
    value: '',
    required: true,
    maxLength: 50,
  },
  {
    fieldType: 'input',
    type: 'text',
    name: 'person',
    label: 'Պատասխանատու անձ',
    value: '',
    required: true,
    maxLength: 50,
  },
];
