import * as Yup from 'yup';
import { InputFiledMessage, isRequired } from './ErrorMessage';

export const WarehouseFormValidation = () =>
  Yup.object().shape({
    storeNo: Yup.string()
      .matches(/[0-9]/, 'Պետք է լինի թիվ')
      .required(InputFiledMessage),
    storeName: Yup.string().required(isRequired),

    person: Yup.string().required(isRequired),
  });
