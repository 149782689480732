import React from 'react';
import Alert from '../../../App/Components/Alert';
import useWarehouseReleaseOrder from '../../hooks/useWarehouseReleaseOrder';
import WarehouseReleaseForm from './WarehouseReleaseForm';

const EventEditForm = ({ params, rowData, handleClose }) => {
  const { matOutputDocID } = rowData || {};
  const {
    errorMess,
    setErrorMess,
    warehouseReleaseEntryOrder,
    addReleaseEntry,
  } = useWarehouseReleaseOrder();

  const onUpdateHandler = async (values) => {
    try {
      await addReleaseEntry(
        {
          matOutput: {
            ...values,
            editInput: true,
            docNum: Number(values.docNum),
          },
          outputs: warehouseReleaseEntryOrder?.outputs,
        },
        params,
        handleClose,
      );
    } catch (err) {
      setErrorMess(err.message);
    }
  };

  return (
    <>
      <WarehouseReleaseForm
        onSubmit={onUpdateHandler}
        handleClose={handleClose}
        matOutputDocID={matOutputDocID}
      />
      {errorMess && (
        <Alert description={errorMess} onClose={() => setErrorMess(null)} />
      )}
    </>
  );
};

export default EventEditForm;
