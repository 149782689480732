import React, { useState } from 'react';
import Table from '../../../../App/Components/Table/Table';
import { Commas } from '../../../../App/Utilities/Utilities';
import DeleteEntryOrderFormation from './DeleteEntryOrderFormation';
import useEInvoiceEntry from '../../../hooks/useEInvoiceEntry';
const EntryOrderFormationTable = ({ entryOrderFormation }) => {
  const { deleteEntryOrder } = useEInvoiceEntry();
  const [openDeleteModal, setOpenDeleteModal] = useState('');
  const columnConfig = [
    {
      title: 'ՆԱ կոդը',
      cell: (row) => row.matValueNo,
      customStyle: { maxWidth: 60 },
    },
    {
      title: 'ՆԱ անվանումը',
      cell: (row) => row.matValueName,
      customStyle: { maxWidth: 300, overflow: 'hidden' },
    },
    {
      title: 'Չ/Մ',
      cell: (row) => row.unit,
      customStyle: { maxWidth: 100, overflow: 'hidden' },
    },
    {
      title: 'Քանակը',
      cell: (row) => row.amount,
      customStyle: { maxWidth: 70 },
    },
    {
      title: 'Գնման գինը',
      cell: (row) => Commas(row.purPrice),
      customStyle: { maxWidth: 160 },
    },
    {
      title: 'Եկ. հարկ',
      cell: (row) => row.incomeTax,
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Գին',
      cell: (row) => Commas(row.price),
      customStyle: { maxWidth: 500 },
    },
    {
      title: 'Գումարը',
      cell: (row) => Commas(row.totalPrice),
      customStyle: { maxWidth: 160 },
    },

    {
      title: 'Հաշիվ',
      cell: (row) => row.accCode,
      customStyle: { maxWidth: 120 },
    },

    {
      title: 'Գործողություն',
      cell: (row) => (
        <span
          className="G-delete-span"
          onClick={() => setOpenDeleteModal(row?.invGoodID)}
        >
          Ջնջել
        </span>
      ),
      customStyle: { maxWidth: 100 },
    },
  ];

  return (
    <>
      <Table
        customClass=""
        data={entryOrderFormation}
        columnConfig={columnConfig}
      />
      {openDeleteModal && (
        <DeleteEntryOrderFormation
          invGoodID={openDeleteModal}
          deleteEntryOrder={deleteEntryOrder}
          handleClose={() => setOpenDeleteModal(false)}
        />
      )}
    </>
  );
};

export default EntryOrderFormationTable;
