export const FETCH_WARE_HOUSE_RELEASE_ORDER = 'FETCH_WARE_HOUSE_RELEASE_ORDER';
export const FETCH_PRODUCT_MATERIAL_VALUE = 'FETCH_PRODUCT_MATERIAL_VALUE';
export const FETCH_RELEASE_ENTRY_ORDER = 'FETCH_RELEASE_ENTRY_ORDER';
export const DELETE_WARE_HOUSE_RELEASE_ORDER =
  'DELETE_WARE_HOUSE_RELEASE_ORDER';

export const ADD_NEW_MATERIAL_RELEASE = 'ADD_NEW_MATERIAL_RELEASE';
export const UPDATE_MATERIAL_RELEASE = 'UPDATE_MATERIAL_RELEASE';
export const DELETE_MATERIAL_RELEASE = 'DELETE_MATERIAL_RELEASE';
export const IS_UPDATE_STORE_NUM = 'IS_UPDATE_STORE_NUM';
export const PRODUCT_TABLE_IS_UPDATE = 'PRODUCT_TABLE_IS_UPDATE';
