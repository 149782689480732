import React, { useEffect, useState } from 'react';
import Modal from '../../../App/Components/Modal/Modal';
import EntryOperationExecutionTable from './EntryOperationExecutionTable';
import EntryOperationExecutionData from './EntryOperationExecutionData';
import useWarehouseEntryOperations from '../../hooks/useWarehouseEntryOperations';
import './style.scss';
import GetSentDataBtn from '../../../App/Components/GetSentDataBtn/GetSentDataBtn';
import { dataTimeFormat } from '../../../App/Utilities/Utilities';
import Alert from '../../../App/Components/Alert';

const EntryOrderOperationExecution = ({
  matInputDocID,
  handleClose,
  params,
}) => {
  const {
    getEntryOperationExecution,
    entryOperationExecution,
    saveEntryOpExecution,
    errorMessage,
    setErrorMessage,
  } = useWarehouseEntryOperations();

  const [formData, setFormData] = useState({});
  const [modifiedEntry, setModifiedEntry] = useState([]);

  useEffect(() => {
    getEntryOperationExecution(matInputDocID);
  }, [matInputDocID]);

  useEffect(() => {
    setFormData(entryOperationExecution?.matInput || {});
  }, [entryOperationExecution?.matInput]);

  useEffect(() => {
    const entries = Array.isArray(entryOperationExecution?.entries)
      ? entryOperationExecution.entries.map((entry) => ({
          ...entry,
          docDate: dataTimeFormat(formData.docDate),
          creditAcc: entry.creditAcc || formData.creditAcc,
        }))
      : [];
    setModifiedEntry(entries);
  }, [entryOperationExecution?.entries, formData]);

  const isDisabled =
    (formData?.inputTypeId === 3 || formData?.inputTypeId === 4) &&
    !formData?.creditAcc;

  const onAddHandler = async () => {
    const updatedEntries = Array.isArray(entryOperationExecution?.entries)
      ? entryOperationExecution.entries.map((entry) => ({
          ...entry,
          docDate: dataTimeFormat(formData.docDate),
          creditAcc: entry.creditAcc || formData.creditAcc,
        }))
      : [];

    const updateNewData = {
      creditAcc: formData.creditAcc,
      entries: updatedEntries,
    };

    try {
      await saveEntryOpExecution(updateNewData, params, handleClose);
    } catch (error) {
      console.error('Error saving entry operation execution:', error.message);
    }
  };
  const handleAlertClose = () => {
    setErrorMessage('');
    handleClose();
  };

  return errorMessage ? (
    <Alert description={errorMessage} onClose={handleAlertClose} />
  ) : (
    <Modal
      onDrag={true}
      closeHandler={handleAlertClose}
      customClass="L-entryOrder-operation-execution-form"
      title="Գործառության կատարում"
    >
      <>
        <EntryOperationExecutionData
          formData={formData}
          setFormData={setFormData}
          entryOperationExecution={entryOperationExecution}
          setErrorMessage={setErrorMessage}
        />

        <EntryOperationExecutionTable operationExecutionData={modifiedEntry} />

        <GetSentDataBtn
          cancelPrintClick={handleClose}
          confirmExecuteClick={onAddHandler}
          executeClass={isDisabled ? 'G-disabled-button' : ''}
          executeDisabled={isDisabled}
        />
      </>
    </Modal>
  );
};

export default EntryOrderOperationExecution;
