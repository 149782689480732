import React from 'react';
import { useDispatch } from 'react-redux';
import MaterialReleaseForm from './MaterialReleaseForm';
import { addMaterialRelease } from '../../../../App/Redux/materialValuesStor/warehouseReleaseOrderSlices/useAction';

const EventAddForm = ({ handleClose }) => {
  const dispatch = useDispatch();

  const handleAddProduct = (values, resetForm) => {
    dispatch(addMaterialRelease({ outputs: [...values], tableIsUpdate: true }));
    resetForm();
    handleClose();
  };

  return (
    <MaterialReleaseForm
      onSubmit={handleAddProduct}
      handleClose={handleClose}
    />
  );
};

export default EventAddForm;
