import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

const YearPicker = ({
  initialYear,
  onYearChange,
  yearItemNumber = 4,
  dateFormat = 'yyyy',
  disabled,
  minDate,
  maxDate,
}) => {
  return (
    <DatePicker
      selected={initialYear}
      onChange={onYearChange}
      showYearPicker
      dateFormat={dateFormat}
      yearItemNumber={yearItemNumber}
      disabled={disabled}
      calendarClassName="custom-datepicker"
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

export default YearPicker;
